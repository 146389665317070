import React from "react"

import CloseMenu from "../assets/images/close-menu.svg"
import Hamburger from "../assets/images/hamburger.svg"
import HeaderLogo from "../assets/images/Logo.svg"
import Link from "../utils/link"

import "./header.scss"

const Header = () => {
  const [isVisible, setIsVisible] = React.useState(false)

  const toggleIsVisible = () => {
    setIsVisible(!isVisible)
  }

  React.useEffect(() => {
    isVisible
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "visible")
  }, [isVisible])

  const headerLinks = [
    {
      link: "/about-us",
      name: "About us",
    },
    {
      link: "/locations",
      name: "Locations",
    },
    {
      link: "/contact",
      name: "Contact",
    },
  ]

  return (
    <header className={`header ${isVisible ? "header--flyout" : ""}`}>
      <div className="header__container">
        <nav className="header__nav">
          <Link to="/" title="HOME" className="header__logo">
            <img src={HeaderLogo} alt="logo" />
          </Link>

          <div className="header__nav__right">
            <ul className="header__nav__links">
              {headerLinks.map((link, index) => {
                return (
                  <li key={index} className="header__nav__links__link">
                    <Link to={link.link}>{link.name}</Link>
                  </li>
                )
              })}
            </ul>

            <Link to="/login" className="header__nav__signin">
              Sign In
            </Link>
          </div>

          <div onClick={toggleIsVisible} className="header__nav__hamburger">
            <img src={isVisible ? CloseMenu : Hamburger} />
          </div>
        </nav>

        {isVisible && (
          <div className="header--flyout">
            <div className="header--flyout__container">
              <ul className="header--flyout__links">
                {headerLinks.map((link, index) => {
                  return (
                    <li key={index} className="header--flyout__links__link">
                      <Link to={link.link}>{link.name}</Link>
                    </li>
                  )
                })}
              </ul>

              <div>
                <div className="landing-banner__content__buttons">
                  <Link
                    to="/register"
                    className="landing-banner__content__buttons__register"
                  >
                    Register
                  </Link>

                  <Link
                    to="/login"
                    className="landing-banner__content__buttons__login"
                  >
                    Sign in
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
