import React from "react"

import Email from "../assets/images/Icons/email-circle.svg"
import Message from "../assets/images/Icons/message-circle.svg"
import Phone from "../assets/images/Icons/phone-circle.svg"
import Skate from "../assets/images/Illustrations/skate.svg"
import LogoFooter from "../assets/images/Logo-light.svg"
import Link from "../utils/link"

import "./footer.scss"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__left">
          <div className="footer__left__top">
            <p className="footer__left__heading">
              Ready to make your laundry sparkle?
            </p>

            <div className="footer__left__buttons">
              <Link to="/register" className="footer__left__buttons__register">
                Register
              </Link>

              <Link to="/login" className="footer__left__buttons__signin">
                Sign in
              </Link>
            </div>
          </div>

          <div className="footer__left__bottom footer__left__bottom--pc">
            <Link to="/" title="Site Name" className="footer__left__logo">
              <img src={LogoFooter} />
            </Link>

            <p className="footer__left__tagline">
              The simplest way to keep on top of your washing.
            </p>
          </div>
        </div>

        <div className="footer__right">
          <div className="footer__right__top">
            <div>
              <Link
                to="mailto: hello@washroom.com.au"
                className="footer__right__top__email"
              >
                hello@washroom.com.au
              </Link>

              <Link to="tel:1300432896" className="footer__right__top__contact">
                1300 432 896
              </Link>
            </div>

            <div className="footer__right__top__icons">
              <Link
                to="sms://1300432896"
                title="message"
                className="footer__right__top__icon"
              >
                <img src={Message} />
              </Link>

              <Link
                to="mailto:hello@washroom.com.au"
                title="mail"
                className="footer__right__top__icon"
              >
                <img src={Email} />
              </Link>

              <Link
                to="tel:1300432896"
                title="phone"
                className="footer__right__top__icon"
              >
                <img src={Phone} />
              </Link>
            </div>
          </div>

          <img src={Skate} className="footer__right__image" />

          <div className="footer__right__bottom">
            <div className="footer__left__bottom footer__left__bottom--mobile">
              <Link to="/" title="Site Name" className="footer__left__logo">
                <img src={LogoFooter} />
              </Link>

              <p className="footer__left__tagline">
                The simplest way to keep on top of your washing.
              </p>
            </div>

            <div>
              <Link to="#" className="footer__right__bottom__terms">
                Terms of Use
              </Link>

              <p className="footer__right__bottom__rights">
                All rights reserved 2021. Design by Atollon
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
